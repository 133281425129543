import { Modal, notification } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useIsThirdGen } from '@signifyd/components'
import { ButtonProps } from 'antd/lib/button'
import BulkActionsReadMoreModalContent from './BulkActionsReadMoreModalContent'
import { useBulkActionsContext } from '../BulkActionsProvider'
import { MODAL_WIDTH } from '../constants'

const BulkActionsReadMoreModal: FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isThirdGen = useIsThirdGen()

  const {
    failedOutcomes,
    setFailedOutcomes,
    setBulkActionType,
    setBulkActionStage,
  } = useBulkActionsContext()

  const handleCancel = (): void => {
    setFailedOutcomes([])
    setBulkActionType(undefined)
    setBulkActionStage('NOTES')
    notification.destroy('bulkActionsNotification-error')
  }

  const params = new URLSearchParams(
    failedOutcomes.map((outcome) => [
      'investigationId',
      outcome.investigationId.toString(),
    ])
  )

  const okButtonProps = {
    'data-analytics-id': 'bulk-actions-read-more-ok',
    href: `${pathname}?${params}`,
    target: '_blank',
    rel: 'noopener noreferrer',
  }

  const cancelButtonProps = {
    'data-analytics-id': 'bulk-actions-read-more-cancel',
    'data-test-id': 'bulkActionsReadMoreCancelButton',
    type: 'link',
  } as unknown as ButtonProps

  return (
    <Modal
      width={MODAL_WIDTH}
      data-test-id="bulkActionsReadMoreModal"
      title={t('bulkActions.readMoreModal.modalTitle')}
      open
      okButtonProps={okButtonProps}
      okText={t('bulkActions.readMoreModal.okText', {
        count: failedOutcomes.length,
      })}
      okType={isThirdGen ? 'primary' : 'link'}
      cancelButtonProps={cancelButtonProps}
      cancelText={t('bulkActions.readMoreModal.cancelText')}
      onCancel={handleCancel}
      destroyOnClose
      closable={false}
      maskClosable={false}
    >
      <BulkActionsReadMoreModalContent failedOutcomes={failedOutcomes} />
    </Modal>
  )
}

export default BulkActionsReadMoreModal
