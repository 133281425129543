import { UnorderedListOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetIsBulkAddToListEnabled,
  useGetBulkActionPredictionLists,
} from 'pages/InvestigationSearchPage/components/BulkActions/queries/useGetBulkActionsPredictionLists'
import { useBulkActionsContext } from '../../BulkActionsProvider'
import BulkActionsButton from '../BulkActionsButton'

const BulkAddToListButton: FC = () => {
  const { t } = useTranslation()

  const { selectedInvestigations, setBulkActionType, setBulkActionStage } =
    useBulkActionsContext()

  const teamsSet = new Set(selectedInvestigations.map(({ teamId }) => teamId))

  const { data: isEnabled, isLoading: hasAnyListsLoading } =
    useGetIsBulkAddToListEnabled()

  const { data: predictionListData, isLoading: predictionListDataLoading } =
    useGetBulkActionPredictionLists()

  const hasListsForTeam = predictionListData?.hasListsForTeam

  const singleTeamSelected = teamsSet.size === 1

  const isDisabled = !singleTeamSelected || !hasListsForTeam

  const getTooltipTitle = (): string | undefined => {
    if (!singleTeamSelected) {
      return t('bulkActions.bulkAddToListButton.tooltip.tooManyTeamsSelected', {
        count: teamsSet.size,
      })
    }

    if (!hasListsForTeam) {
      return t('bulkActions.bulkAddToListButton.tooltip.noLists')
    }

    return undefined
  }

  if (!isEnabled) {
    return null
  }

  return (
    <Tooltip title={getTooltipTitle()}>
      <div>
        <BulkActionsButton
          buttonKey="addToListButton"
          Icon={<UnorderedListOutlined />}
          disabled={isDisabled}
          onClick={() => {
            setBulkActionType('ADD_TO_LIST')
            setBulkActionStage('SELECT_LIST_TYPE')
          }}
          loading={predictionListDataLoading || hasAnyListsLoading}
        />
      </div>
    </Tooltip>
  )
}

export default BulkAddToListButton
