import { DislikeFilled, LikeFilled } from '@ant-design/icons'
import cx from 'classnames'
import { FC } from 'react'
import { i18nInstance } from '@signifyd/components'
import { GUARANTEE_REVIEW_DISPOSITION } from '@signifyd/http'

import styles from './ReviewDispositionCell.less'

export enum GUARANTEE_REVIEW_DISPOSITION_VALUES {
  GOOD = 'GOOD',
  FRAUDULENT = 'FRAUDULENT',
  UNSET = 'UNSET',
}

interface Props {
  text: GUARANTEE_REVIEW_DISPOSITION_VALUES
}

const dispositionToIcon = {
  [GUARANTEE_REVIEW_DISPOSITION.GOOD]: LikeFilled,
  [GUARANTEE_REVIEW_DISPOSITION.FRAUDULENT]: DislikeFilled,
}

const ReviewDispositionCell: FC<Props> = ({ text }) => {
  const ReviewDispositionIcon = dispositionToIcon[text]

  if (!ReviewDispositionIcon) {
    return null
  }

  return (
    <div
      className={cx(
        styles.reviewDispositionWrapper,
        text === GUARANTEE_REVIEW_DISPOSITION.GOOD && styles.good,
        text === GUARANTEE_REVIEW_DISPOSITION.FRAUDULENT && styles.fraudulent
      )}
    >
      <ReviewDispositionIcon
        className={styles.reviewDispositionIcon}
        title={i18nInstance.t(`icon.orderReviewFlag.${text}`)}
      />
    </div>
  )
}

export default ReviewDispositionCell
