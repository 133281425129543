import { FC } from 'react'
import AddToListFlow from './AddToListFlow'
import BulkActionsPopover from './BulkActionsPopover'
import { useBulkActionsContext } from './BulkActionsProvider'
import BulkActionsReadMoreModal from './BulkActionsReadMoreModal'
import CoreBulkActionFlows from './CoreBulkActionFlows'

const BulkActions: FC = () => {
  const { bulkActionType, bulkActionStage, selectedInvestigations } =
    useBulkActionsContext()

  const isCoreBulkActionType =
    bulkActionType === 'ADD_NOTE' ||
    bulkActionType === 'SUBMIT_FOR_GUARANTEE' ||
    bulkActionType === 'THUMBS_DOWN'

  return (
    <>
      {!!selectedInvestigations.length && (
        <>
          {!bulkActionType && <BulkActionsPopover />}
          {isCoreBulkActionType && <CoreBulkActionFlows />}
          {bulkActionType === 'ADD_TO_LIST' && <AddToListFlow />}
        </>
      )}
      {bulkActionStage === 'READ_MORE' && <BulkActionsReadMoreModal />}
    </>
  )
}

export default BulkActions
