import { FC } from 'react'
import { colorEaton, colorEmerald } from '@signifyd/colors'
import { IconProps, i18nInstance } from '@signifyd/components'
import { INV_GUARANTEE_DISPOSITION } from '@signifyd/http'
import {
  GenericDispositionTags,
  getGenericDispositionsTags,
} from './dispositionTags.utils'

const AUTOMATIC_DECISION = 'AUTO'
const MACHINE_DECISION = 'MR'

export interface DispositionTagProps {
  text: INV_GUARANTEE_DISPOSITION
  isDecidedByMR: boolean
}

type DispositionType =
  | INV_GUARANTEE_DISPOSITION.APPROVED
  | INV_GUARANTEE_DISPOSITION.DECLINED

type DecisioningType = typeof AUTOMATIC_DECISION | typeof MACHINE_DECISION

type DispositionTagType =
  `GUARANTEE_DISPOSITION_${DispositionType}_${DecisioningType}`

type GuaranteeIconProps = IconProps & { type: DispositionTagType }

const getAdminGuaranteeDispositionIcons = ({
  type,
  title,
}: GuaranteeIconProps): JSX.Element => {
  const icons: Record<DispositionTagType, JSX.Element> = {
    GUARANTEE_DISPOSITION_APPROVED_AUTO: (
      <svg width="30" height="30" viewBox="0 0 30 30">
        <title>{title}</title>
        <path
          fill={colorEmerald}
          fillRule="nonzero"
          d="M25.32 6a.68.68 0 0 1 .68.68v12.95a.68.68 0 0 1-.68.68H16.76c.057.866.312 2.366 1.395 2.474.027.002.048.005.066.008h.129a.604.604 0 1 1 0 1.208H11.65a.604.604 0 0 1 0-1.208h.13c.017-.003.038-.006.065-.008 1.083-.108 1.338-1.608 1.395-2.473H4.679A.68.68 0 0 1 4 19.63V6.68A.68.68 0 0 1 4.68 6h20.64zm-14.668 7.353c-.21.222-.199.665.011.88l2.561 2.596c.216.222.647.228.87.018l.407-.391.881-.846 4.281-4.083c.222-.21.228-.641.012-.857l-.496-.507c-.216-.216-.647-.216-.863-.006l-4.188 4.182c-.216.216-.647.21-.857-.006l-1.394-1.406c-.21-.221-.63-.216-.84.012l-.385.414z"
        />
      </svg>
    ),
    GUARANTEE_DISPOSITION_DECLINED_AUTO: (
      <svg width="30" height="30" viewBox="0 0 30 30">
        {title && <title>{title}</title>}
        <path
          fill={colorEaton}
          fillRule="nonzero"
          d="M25.32 6a.68.68 0 0 1 .68.68v12.95a.68.68 0 0 1-.68.68H16.76c.057.866.312 2.366 1.395 2.474.027.002.048.005.066.008h.129a.604.604 0 1 1 0 1.208H11.65a.604.604 0 0 1 0-1.208h.13c.017-.003.038-.006.065-.008 1.083-.108 1.338-1.608 1.395-2.473H4.679A.68.68 0 0 1 4 19.63V6.68A.68.68 0 0 1 4.68 6h20.64zM11.5 10.917l2.333 2.333-2.333 2.333 1.167 1.167L15 14.417l2.333 2.333 1.167-1.167-2.333-2.333 2.333-2.333-1.167-1.167L15 12.083 12.667 9.75 11.5 10.917z"
        />
      </svg>
    ),
    GUARANTEE_DISPOSITION_APPROVED_MR: (
      <svg width="30" height="30" viewBox="0 0 30 30">
        <title>{title}</title>
        <path
          fill={colorEmerald}
          fillRule="nonzero"
          d="M9.761 24.607s.11-4.09-.773-6.01c0 0-.43.444-.908 0-.48-.445-2.184-4.154.025-5.018 0 0-1.443-5.28 2.59-7.634.077-.102-.2-1.35 5.847-1.945 0 0 .126.67 1.622 1.647 4.742 2.168 3.168 7.932 3.168 7.932 2.208.865.504 4.573.025 5.017-.48.446-.908 0-.908 0-.884 1.922-.71 6.325-.71 6.325.11 1.633-10.089 1.32-9.978-.314zm.39-9.254c-.21.222-.198.665.012.88l2.561 2.596c.216.222.647.228.87.018l.407-.391.881-.846 4.281-4.083c.222-.21.228-.641.012-.857l-.496-.507c-.216-.216-.647-.216-.863-.006l-4.188 4.182c-.216.216-.647.21-.857-.006l-1.394-1.406c-.21-.221-.63-.216-.84.012l-.385.414z"
        />
      </svg>
    ),
    GUARANTEE_DISPOSITION_DECLINED_MR: (
      <svg width="30" height="30" viewBox="0 0 30 30">
        <title>{title}</title>
        <path
          fill={colorEaton}
          fillRule="nonzero"
          d="M9.761 24.607s.11-4.09-.773-6.01c0 0-.43.444-.908 0-.48-.445-2.184-4.154.025-5.018 0 0-1.443-5.28 2.59-7.634.077-.102-.2-1.35 5.847-1.945 0 0 .126.67 1.622 1.647 4.742 2.168 3.168 7.932 3.168 7.932 2.208.865.504 4.573.025 5.017-.48.446-.908 0-.908 0-.884 1.922-.71 6.325-.71 6.325.11 1.633-10.089 1.32-9.978-.314zm1.489-12.44l2.333 2.333-2.333 2.333L12.417 18l2.333-2.333L17.083 18l1.167-1.167-2.333-2.333 2.333-2.333L17.083 11l-2.333 2.333L12.417 11l-1.167 1.167z"
        />
      </svg>
    ),
  }

  return icons[type]
}

const AdminDispositionIcon: FC<DispositionTagProps> = ({
  text,
  isDecidedByMR,
}) => {
  if (
    text === INV_GUARANTEE_DISPOSITION.APPROVED ||
    text === INV_GUARANTEE_DISPOSITION.DECLINED
  ) {
    const tag = isDecidedByMR ? MACHINE_DECISION : AUTOMATIC_DECISION

    const guaranteeMRSvgIcon: DispositionTagType = `GUARANTEE_DISPOSITION_${text}_${tag}`

    return getAdminGuaranteeDispositionIcons({
      type: guaranteeMRSvgIcon,
      title: i18nInstance.t(`icon.guaranteeDisposition.${text}_${tag}`),
    })
  }

  return (
    getGenericDispositionsTags(text as GenericDispositionTags) ??
    INV_GUARANTEE_DISPOSITION[text] ??
    text
  )
}

export default AdminDispositionIcon
