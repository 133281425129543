import { notification } from 'antd'
import { i18nInstance } from '@signifyd/components'
import {
  INV_REVIEW_DISPOSITION,
  InvestigationInfo,
  bulkCheckGuaranteeEligibility,
  GuaranteeEligibility,
} from '@signifyd/http'
import { THUMBS_UP_REQUIRED_CUSTOMERS } from 'pages/InvestigationSearchPage/components/BulkActions/BulkActionsPopover/data'
import { EvaluatedInvestigation } from './types'

interface InitialEvaluationData {
  clientEvaluations: Array<EvaluatedInvestigation>
  remainingInvestigations: Array<InvestigationInfo>
}

const getEvaluatedInvestigations = (
  summaries: Array<GuaranteeEligibility>,
  investigations: Array<InvestigationInfo>
): Array<EvaluatedInvestigation> => {
  const summaryMap: Map<number, GuaranteeEligibility> = new Map(
    summaries.map((summary) => [summary.caseId, summary])
  )

  return investigations.map((investigation) => {
    const eligibility = summaryMap.get(investigation.investigationId)

    if (!eligibility) {
      return {
        investigation,
        disabledReason: i18nInstance.t(
          'bulkActions.confirmationModal.resubmit.reason'
        ),
      }
    }

    if (eligibility.eligible) {
      return { investigation }
    }

    return {
      investigation,
      disabledReason: i18nInstance.t(
        'bulkActions.confirmationModal.resubmit.reason',
        {
          context: eligibility.reason,
        }
      ),
    }
  })
}

const evaluateInvestigationForAdditionalRequirements = (
  investigation: InvestigationInfo
): string | null => {
  if (investigation.claims.length) {
    return i18nInstance.t('bulkActions.confirmationModal.hasClaim')
  }

  if (
    THUMBS_UP_REQUIRED_CUSTOMERS.includes(investigation.customerId) &&
    investigation.investigationReviewDisposition !== INV_REVIEW_DISPOSITION.GOOD
  ) {
    return i18nInstance.t('bulkActions.confirmationModal.requiresDisposition')
  }

  return null
}

const evaluateClientSideConditions = (
  investigations: Array<InvestigationInfo>
): InitialEvaluationData =>
  investigations.reduce(
    (partition: InitialEvaluationData, investigation) => {
      const disabledReason =
        evaluateInvestigationForAdditionalRequirements(investigation)

      if (disabledReason !== null) {
        partition.clientEvaluations.push({
          investigation,
          disabledReason,
        })
      } else {
        partition.remainingInvestigations.push(investigation)
      }

      return partition
    },
    {
      clientEvaluations: [],
      remainingInvestigations: [],
    }
  )

export const evaluateInvestigationsForGuarantee = async (
  investigations: Array<InvestigationInfo>
): Promise<Array<EvaluatedInvestigation>> => {
  const evaluations: Array<EvaluatedInvestigation> = []

  // TODO FET-2131 - Remove when backend translations are in place
  const { clientEvaluations, remainingInvestigations } =
    evaluateClientSideConditions(investigations)

  evaluations.push(...clientEvaluations)

  if (!remainingInvestigations.length) {
    return evaluations
  }

  try {
    const {
      data: { guaranteeEligibilitySummaries },
    } = await bulkCheckGuaranteeEligibility(
      investigations.map((inv) => inv.investigationId)
    )

    const serverEvaluations = getEvaluatedInvestigations(
      guaranteeEligibilitySummaries,
      remainingInvestigations
    )

    evaluations.push(...serverEvaluations)

    return evaluations
  } catch (error) {
    console.error(error)

    notification.error({
      message: i18nInstance.t('bulkActions.notification.eligibilityApiFailure'),
    })

    return investigations.map((investigation) => ({
      investigation,
      disabledReason: i18nInstance.t(
        'bulkActions.confirmationModal.resubmit.reason',
        { context: 'apiFailure' }
      ),
    }))
  }
}

export const evaluateInvestigationsForThumbsDown = (
  investigations: Array<InvestigationInfo>
): Array<EvaluatedInvestigation> =>
  investigations.map((investigation) => ({
    investigation,
    disabledReason:
      investigation.investigationReviewDisposition ===
      INV_REVIEW_DISPOSITION.BAD
        ? i18nInstance.t('bulkActions.confirmationModal.alreadyMarkedAsBad')
        : undefined,
  }))
