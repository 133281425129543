import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { toNumber } from 'lodash'
import { i18nInstance } from '@signifyd/components'
import { formatCurrencyNumber } from '@signifyd/utils'
import { EvaluatedInvestigation } from 'pages/InvestigationSearchPage/hooks/useGetEvaluatedInvestigations'
import { ColumnProps } from 'antd/lib/table'
import styles from './BulkActionsConfirmationModalContent.less'
import { AddToListEvaluatedInvestigation } from '../flows/AddToList/AddToListConfirmationModal/useGetSelectedAddToListRows'
import { BulkActionType } from '../types'

export const TABLE_HEIGHT = 302

export type SearchTableColumnProps = ColumnProps<EvaluatedInvestigation>

const getConfirmTableColumns = (): Array<SearchTableColumnProps> => {
  return [
    {
      title: i18nInstance.t('bulkActions.confirmationModal.columns.caseId'),
      dataIndex: ['investigation', 'investigationId'],
      key: 'investigation.investigationId',
      width: 110,
    },
    {
      title: i18nInstance.t('bulkActions.confirmationModal.columns.team'),
      dataIndex: ['investigation', 'teamName'],
      key: 'investigation.teamName',
      width: 140,
      className: styles.truncate,
    },
    {
      title: i18nInstance.t('bulkActions.confirmationModal.columns.orderTotal'),
      dataIndex: ['investigation', 'orderTotalAmount'],
      key: 'investigation.orderTotalAmount',
      width: 120,
      render: (_text: null, { investigation }: EvaluatedInvestigation) => {
        return `${investigation.currency || ''} ${formatCurrencyNumber(
          toNumber(investigation.orderTotalAmount)
        )}`
      },
    },
  ]
}

const getTooltipColumn = (): Array<SearchTableColumnProps> => {
  return [
    {
      title: '',
      dataIndex: 'disabledReason',
      key: 'disabledReason',
      width: 40,
      render: (_text: null, { disabledReason }: EvaluatedInvestigation) => {
        if (!disabledReason) {
          return null
        }

        return (
          <Tooltip
            title={disabledReason}
            placement="topLeft"
            arrow={{ pointAtCenter: true }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        )
      },
    },
  ]
}

export const getAddToListColumns = (
  valueColumnTitle: string
): Array<ColumnProps<AddToListEvaluatedInvestigation>> => {
  return [
    ...getTooltipColumn(),
    {
      title: i18nInstance.t('bulkActions.confirmationModal.columns.caseId'),
      dataIndex: ['investigation', 'investigationId'],
      key: 'investigation.investigationId',
      width: 110,
    },
    {
      title: i18nInstance.t('bulkActions.confirmationModal.columns.team'),
      dataIndex: ['investigation', 'teamName'],
      key: 'investigation.teamName',
      width: 140,
      className: styles.truncate,
    },
    {
      title: valueColumnTitle,
      dataIndex: 'value',
      key: 'value',
      width: 120,
    },
  ]
}

export const getColumns = (
  bulkActionType: BulkActionType
): Array<SearchTableColumnProps> => {
  const isBulkActionAddNote = bulkActionType === 'ADD_NOTE'

  if (!isBulkActionAddNote) {
    return [...getTooltipColumn(), ...getConfirmTableColumns()]
  }

  return getConfirmTableColumns()
}
