import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useIsThirdGen } from '@signifyd/components'
import {
  FlexibleSearchRequest,
  INV_SEARCH_VIEW,
  SEARCH_BOOL_OPERATOR,
  getLinkedInvestigationSearch,
} from '@signifyd/http'
import { createFlexibleSearchRequest, SearchKeys } from '@signifyd/utils'
import useGetUserData from 'core/hooks/useGetUserData'
import useSimilarityParams, {
  SimilarityHook,
} from 'core/hooks/useSimilarityParams'
import { SearchResultsResponse } from './useGetSearchResults'
import { createEnhancedInvestigations } from '../containers/SearchResultsTable/SearchResultsTable.utils'
import { useGlobalFilterJoinType } from '../queries/useGlobalFilterJoinType'
import { useTablePagination } from '../queries/useTablePagination'
import { useTableQueryParams } from '../queries/useTableQueryParams'
import { useTableSorting } from '../queries/useTableSorting'

const MAX_SEARCH_RESULTS = 10000

const createSimilarityQuery = <V extends INV_SEARCH_VIEW>(
  flexibleSearchQuery: FlexibleSearchRequest<V>,
  similarity: SimilarityHook
): FlexibleSearchRequest<V> => {
  const { similarityInvestigationId, similarityAttributes } = similarity

  return {
    ...flexibleSearchQuery,
    query: {
      [SEARCH_BOOL_OPERATOR.OR]: [{ ...flexibleSearchQuery.query }],
    },
    link: {
      investigationId: similarityInvestigationId,
      fieldNames: similarityAttributes,
    },
  }
}

const useGetSimilaritySearchResults =
  (): UseQueryResult<SearchResultsResponse> => {
    const { t } = useTranslation()
    const { query } = useTableQueryParams()
    const similarity = useSimilarityParams()
    const { currentUser, isTraining } = useGetUserData()

    const { normalizedPurchaseCreatedAt, ...rest } = query

    const {
      query: { current, pageSize },
    } = useTablePagination()

    const {
      query: { sortOrder, orderBy },
    } = useTableSorting()

    const { joinType } = useGlobalFilterJoinType()

    const isThirdGen = useIsThirdGen()

    const notificationKey = currentUser?.isAdmin
      ? 'notificationAdmin'
      : 'notification'

    // As the user sorts & filters, it will update the query string which will cascade to this query and update search results
    return useQuery(
      [
        'similaritySearchResults',
        normalizedPurchaseCreatedAt,
        rest,
        current,
        pageSize,
        sortOrder,
        orderBy,
        joinType,
        similarity,
      ],
      async () => {
        const flexibleSearchQuery = createFlexibleSearchRequest({
          filters: query,
          // We know these exist because they are checked in the enabled flag
          pagination: {
            currentPage: current!,
            size: pageSize!,
          },
          sort: {
            by: orderBy as SearchKeys,
            order: sortOrder!,
          },
          joinType: joinType!,
        })

        const similaritySearchQuery = createSimilarityQuery(
          flexibleSearchQuery,
          similarity!
        )

        const { data: search } = await getLinkedInvestigationSearch(
          similaritySearchQuery
        )

        if (!isThirdGen && search.totalResults >= MAX_SEARCH_RESULTS) {
          notification.open({
            key: notificationKey,
            message: t(
              `investigationSearch.limitedResults.${notificationKey}.message`
            ),
            description: t(
              `investigationSearch.limitedResults.${notificationKey}.description`
            ),
            duration: 0,
            style: {
              top: 80,
            },
          })
        } else {
          notification.destroy(notificationKey)
        }

        const { primaryInvestigation, linkedInvestigations, ...rest } = search
        const { totalResults } = search

        const enhancedInvestigations =
          totalResults > 0
            ? createEnhancedInvestigations(
                [
                  // primary investigation to be displayed at the top of the table
                  primaryInvestigation,
                  ...linkedInvestigations,
                ],
                isTraining
              )
            : []

        return {
          ...rest,
          primaryInvestigation,
          flexibleSearchQuery: similaritySearchQuery,
          investigations: enhancedInvestigations,
        }
      },
      {
        enabled:
          !!normalizedPurchaseCreatedAt &&
          !!current &&
          !!pageSize &&
          !!orderBy &&
          !!sortOrder &&
          !!joinType &&
          !!similarity,
        onError: (error) => {
          console.error(error)

          if (typeof error === 'string') {
            message.error(error)
          }
        },
        keepPreviousData: true,
      }
    )
  }

export default useGetSimilaritySearchResults
