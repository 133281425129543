import { Checkbox, Typography } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Fetch, Spinner } from '@signifyd/components'
import {
  ColFilterKey,
  COL_FILTERS,
} from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnFilterConfig'
import useGetAggregateFilters, {
  FilterFields,
} from 'pages/InvestigationSearchPage/hooks/useGetAggregateFilters'
import styles from './MultiSelect.less'

const { Text } = Typography

interface Props {
  value?: Array<string>
  onChange: (values: Array<string>) => void
  columnKey: ColFilterKey
}

const MultiSelect: FC<Props> = ({ value, onChange, columnKey }) => {
  const { t } = useTranslation()
  const columnData = COL_FILTERS[columnKey]

  if (!('dynamicFilterKeys' in columnData)) {
    return null
  }

  const { dynamicFilterKeys = [], getMultiSelectOptions } = columnData

  const ErrorComponent = <Text>{t('dynamicFilters.noFiltersFound')}</Text>

  return (
    <Fetch
      fetchFunctionArgs={[dynamicFilterKeys, columnKey]}
      LoadingComponent={<Spinner fontSize={24} />}
      ErrorComponent={ErrorComponent}
      useFetchFunction={useGetAggregateFilters}
      renderComponent={({ fields }: FilterFields) => {
        if (!fields.length) {
          return ErrorComponent
        }

        const uniqueFields = [
          ...new Set(fields.map((field) => field.value.toString())),
        ]

        const options = getMultiSelectOptions(uniqueFields)

        return (
          <Checkbox.Group
            options={options}
            value={value}
            onChange={(values) => onChange(values as Array<string>)}
            data-analytics-id={`column-filter-checkbox-input-${columnKey}`}
            data-test-id="multiSelectFilterInput"
            className={styles.container}
          />
        )
      }}
    />
  )
}

export default MultiSelect
