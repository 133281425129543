import { FC } from 'react'
import { GuaranteeTagThirdGen } from '@signifyd/components'
import { INV_GUARANTEE_DISPOSITION } from '@signifyd/http'
import AdminDispositionIcon from './AdminDispositionTag'
import styles from './GuaranteeDispositionCell.less'

interface Props {
  text?: INV_GUARANTEE_DISPOSITION
  isDecidedByMR: boolean
  isCustomer: boolean
}

const GuaranteeDispositionCellThirdGen: FC<Props> = ({
  text,
  isDecidedByMR,
  isCustomer,
}) => {
  if (!text) {
    return null
  }

  if (isCustomer) {
    return <GuaranteeTagThirdGen disposition={text} />
  }

  return (
    <div className={styles.tag}>
      <AdminDispositionIcon text={text} isDecidedByMR={isDecidedByMR} />
    </div>
  )
}

export default GuaranteeDispositionCellThirdGen
