import { ReactNode } from 'react'
import { i18nInstance, Text } from '@signifyd/components'
import { InvestigationInfo, Product } from '@signifyd/http'

const ProductsCell = (_text: null, rowData: InvestigationInfo): ReactNode => {
  const { t } = i18nInstance
  const MAX_PRODUCT_CHAR_COUNT = 40

  if (!rowData?.products?.length) {
    return <Text>{t('investigationSearch.products.fallback')}</Text>
  }

  const { products } = rowData

  const priceSortedItems: Array<Product> = [...products].sort(
    (itemA: Product, itemB: Product) => {
      return +itemB.itemPrice - +itemA.itemPrice
    }
  )

  const [mostExpensiveItem, ...restItems] = priceSortedItems

  const productName =
    mostExpensiveItem.itemName ?? t('investigationSearch.products.fallback')

  const truncatedProductName =
    productName.length > MAX_PRODUCT_CHAR_COUNT
      ? `${productName.slice(0, MAX_PRODUCT_CHAR_COUNT)}...`
      : productName

  const { itemId } = mostExpensiveItem

  return (
    <div data-test-id={`productCell-${itemId}`}>
      <Text size="sm">{truncatedProductName}</Text>

      {restItems.length >= 1 && (
        <div>
          <Text size="sm">
            {t('investigationSearch.more', { count: restItems.length })}
          </Text>
        </div>
      )}
    </div>
  )
}

export default ProductsCell
