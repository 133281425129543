import Icon, { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Flex, Skeleton, Tooltip } from 'antd'
import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { Text, DragIcon } from '@signifyd/components'
import investigationSearchColumns from 'locales/en-US/investigationSearch'
import { ColumnProps } from '../../ColumnDrawer'
import styles from './HiddenItems.less'

const TRUNCATE_LENGTH = 35

type ColumnNames = keyof (typeof investigationSearchColumns)['column']

interface Props {
  displayColumns: Array<ColumnProps>
  showColumn: (column: ColumnProps) => void
  loading: boolean
}

const HiddenItems: FC<Props> = ({ displayColumns, showColumn, loading }) => {
  const { t } = useTranslation()

  const hiddenColumns = displayColumns.filter((column) => column.hidden)

  return (
    <Flex gap={8} vertical data-test-id="hiddenColumns">
      {hiddenColumns.map((column) => {
        const { name } = column
        const shouldTruncate = name.length > TRUNCATE_LENGTH
        const title = t(`investigationSearch.column.${name as ColumnNames}`)

        if (loading) {
          return <Skeleton.Input key={name} size="large" block active />
        }

        return (
          <Flex
            align="center"
            justify="space-between"
            key={name}
            className={styles.itemContainer}
            gap={8}
          >
            <Flex align="center" gap={8}>
              <Icon className={styles.icon} component={DragIcon} />
              <Flex align="center" gap={4}>
                <Button
                  type="primary"
                  disabled
                  className={styles.orderButton}
                  size="small"
                >
                  <DownOutlined className={styles.orderButtonIcon} />
                </Button>
                <Button
                  disabled
                  type="primary"
                  className={styles.orderButton}
                  size="small"
                >
                  <UpOutlined className={styles.orderButtonIcon} />
                </Button>
              </Flex>
              {shouldTruncate ? (
                <Tooltip
                  title={<Text className={styles.tooltipText}>{title}</Text>}
                  placement="right"
                >
                  <Text block className={styles.itemText}>
                    {title}
                  </Text>
                </Tooltip>
              ) : (
                <Text>{title}</Text>
              )}
            </Flex>

            <Button
              type="link"
              disabled={loading}
              data-analytics-id={`show-drawer-button-${name}`}
              data-test-id={`showDrawerButton-${name}`}
              onClick={() => showColumn(column)}
            >
              {t(`columnDrawer.showColumn`)}
            </Button>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default HiddenItems
