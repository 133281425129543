import { FC } from 'react'
import {
  CheckpointActionTag,
  ReturnCheckpointMiniIcon,
} from '@signifyd/components'
import { Decision } from '@signifyd/http'

interface Props {
  decision: Decision | null
}

const ReturnCheckpointActionTag: FC<Props> = ({ decision }) => {
  return (
    <CheckpointActionTag
      icon={ReturnCheckpointMiniIcon}
      key={decision?.createdAt}
      action={decision?.checkpointAction ?? null}
    />
  )
}

export default ReturnCheckpointActionTag
