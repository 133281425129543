/* eslint import/order: 0 */
import { render } from 'react-dom'
import { AuthProvider } from '@signifyd/components'

// Bootstrap stylesheets
import 'antd/dist/reset.css'
import '@signifyd/ant/dist/index.less'
import 'react-image-crop/dist/ReactCrop.css'

import App from './App'
import './index.less'

render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('signifyd-power-search')
)
