import { Flex, Popover } from 'antd'
import { FC } from 'react'
import { i18nInstance } from '@signifyd/components'
import { Return } from '@signifyd/http'
import ReturnCheckpointActionTag from './ReturnCheckpointActionTag'
import MultiComponentPopoverButton from '../MultiComponentPopover/MultiComponentPopoverButton'

interface Props {
  returns: Array<Return>
}

const ReturnCheckpointActionCell: FC<Props> = ({ returns }) => {
  const decisions = returns
    .filter(({ decision }) => !!decision)
    .map(({ decision, returnId }) => (
      <ReturnCheckpointActionTag key={returnId} decision={decision} />
    ))

  if (!decisions.length) {
    return null
  }

  return (
    <>
      {decisions[0]}
      {decisions.length > 1 && (
        <Popover
          placement="topLeft"
          title={i18nInstance.t(
            'investigationSearch.column.returnPolicyAction'
          )}
          content={
            <Flex gap={5} vertical>
              {decisions.map((value, index) => {
                return <div key={index}>{value}</div>
              })}
            </Flex>
          }
          trigger="hover"
        >
          <span>
            <MultiComponentPopoverButton amount={decisions.length - 1} />
          </span>
        </Popover>
      )}
    </>
  )
}

export default ReturnCheckpointActionCell
