import { unitOfTime } from 'moment-timezone'
import { InvestigationInfo } from '@signifyd/http'
import columnDrawerLocales from '../locales/en-US/columnDrawer'

export type DefaultColumns =
  keyof (typeof columnDrawerLocales)['defaultColumns']

export const DEFAULT_COLUMNS: Array<DefaultColumns> = [
  'caseId',
  'date',
  'guaranteeDisposition',
]

export const LOOKBACK_LIMIT = {
  MAX_VALUE: 2,
  MAX_VALUE_UNIT: 'years' as unitOfTime.DurationConstructor,
  DEFAULT_VALUE: 2,
  DEFAULT_VALUE_UNIT: 'years' as unitOfTime.DurationConstructor,
}

// note: This list should match the list here: https://github.com/signifyd/platform/blob/master/guarantee/data/src/main/java/com/signifyd/guarantees/Guarantee.java#L47
// maybe todo to pull from backend in the future
const SUPPORT_USER_ID = 1
const NUKE_USER_ID = 25509
const HARD_TIME_OUT_USER_ID = 45137
const TIME_OUT_USER_ID = 45254
const MACHINE_GUN_USER_ID = 45253
export const AUTO_DECISION_REVIEWER_IDS = new Set([
  SUPPORT_USER_ID,
  28000,
  28001,
  28002,
  28003,
  28005,
  HARD_TIME_OUT_USER_ID,
  TIME_OUT_USER_ID,
  NUKE_USER_ID,
  MACHINE_GUN_USER_ID,
]) as Set<number | undefined>

export const SEARCH_CELL_LABELS = {
  // guarantee disposition
  PENDING: 'P',
  IN_REVIEW: 'IR',
  CANCELED: 'C',
  // chargeback
  HAS_CHARGEBACK: 'CB',
}

// TODO FET-1841 https://signifyd.atlassian.net/browse/FET-1841
// This is the actual type after transforming in the query, need to make this work everywhere
export interface EnhancedInvInfo extends InvestigationInfo {
  hasChargeback?: boolean
  isDecidedByMR?: boolean
}

// TODO FET-1981 https://signifyd.atlassian.net/browse/FET-1981 Move to app-configs
export const DISPOSITION_FOR_GUARANTEE_SUBMIT: Record<
  BACKEND_ENV_VAL,
  Array<number>
> = {
  production: [24461, 29631 /* WalmartMX */],
  staging: [24461, 29631 /* WalmartMx */],
}

export const MESSAGE_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
}

export const USER_ROLES = {
  CLAIMS: 'CLAIMS',
  ADMIN: 'ADMIN',
  TRAINING: 'TRAINING',
  MANUAL_REVIEWER: 'MANUAL_REVIEWER',
  MANUAL_REVIEWER_LEAD: 'MANUAL_REVIEWER_LEAD',
  MANUAL_REVIEWER_ADMIN: 'MANUAL_REVIEWER_ADMIN',
  BETA: 'BETA',
}

export const UNKNOWN = 'Unknown'

export const DEFAULT_TIMEZONE = 'US/Pacific'

export const GUARANTEE_DISPOSITION = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
  IN_REVIEW: 'IN_REVIEW',
  CANCELED: 'CANCELED',
  CANCELLED: 'CANCELLED',
  ESCALATED: 'ESCALATED',
}

export const GUARANTEE_PLAN_TYPES = {
  DEFAULT: 'DEFAULT',
  LIVE: 'LIVE',
  TEST: 'TEST',
  TRIAL: 'TRIAL',
  UNRECOGNIZED: 'UNRECOGNIZED',
}

export const ORDERS_APP_URL =
  process.env.BUILD_ENV === 'production'
    ? 'https://console.signifyd.com/orders'
    : 'https://console.staging.signifyd.com/orders'

export const OLD_CONSOLE_URL =
  process.env.BUILD_ENV === 'production'
    ? 'https://app.signifyd.com/cases'
    : 'https://app.staging.signifyd.com/cases'
