import { FC, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { Text } from '@signifyd/components'
import { CORE_BULK_ACTION_TYPE, performBulkAttach } from '@signifyd/http'
import { useGetEvaluatedInvestigations } from 'pages/InvestigationSearchPage/hooks/useGetEvaluatedInvestigations'
import usePerformBulkActions from 'pages/InvestigationSearchPage/hooks/usePerformBulkActions'
import { handleBulkActionNotifications } from 'pages/InvestigationSearchPage/hooks/usePerformBulkActions/bulkActionsNotifications'
import { Key } from 'antd/lib/table/interface'
import BulkActionsConfirmationModal from '../../../BulkActionsConfirmationModal'
import { getColumns } from '../../../BulkActionsConfirmationModal/ColumnConfig'
import { useBulkActionsContext } from '../../../BulkActionsProvider'

const CoreBulkActionsConfirmationModal: FC = () => {
  const { data: evaluation, isLoading } = useGetEvaluatedInvestigations()

  const {
    onReset,
    selectedInvestigations,
    bulkActionType,
    setFailedOutcomes,
    setBulkActionStage,
    fileAttacher,
    note,
  } = useBulkActionsContext()

  const { mutateAsync, isLoading: isLoadingBulkActions } =
    usePerformBulkActions()

  const [selectedRows, setSelectedRows] = useState<Array<Key>>(
    selectedInvestigations.map(({ investigationId }) => investigationId)
  )

  useEffect(() => {
    if (evaluation?.selectedAndValidIds) {
      setSelectedRows(evaluation?.selectedAndValidIds)
    }
  }, [setSelectedRows, evaluation])

  const handleSubmit = async (): Promise<void> => {
    const bulkActionEvaluatedResponse = await mutateAsync({
      investigationIds: selectedRows.map(Number),
      actionType: bulkActionType as CORE_BULK_ACTION_TYPE,
      note,
    })

    setFailedOutcomes(bulkActionEvaluatedResponse.failedOutcomes)

    handleBulkActionNotifications(
      bulkActionType!,
      bulkActionEvaluatedResponse,
      () => setBulkActionStage('READ_MORE')
    )

    const noteIds = bulkActionEvaluatedResponse.successOutcomes.map(
      (item) => item.noteId
    )

    const { files } = fileAttacher

    await Promise.all(
      files.map(async (file) => {
        await performBulkAttach({
          file,
          noteIds,
          fileName: file.name,
        })
      })
    )

    onReset()
  }

  const handlePrevious = (): void => {
    setBulkActionStage('NOTES')
  }

  if (!bulkActionType) {
    return null
  }

  return (
    <BulkActionsConfirmationModal
      evaluation={evaluation}
      isLoading={isLoading || isLoadingBulkActions}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      onSubmit={handleSubmit}
      onPrevious={handlePrevious}
      isVisible
      getColumns={() => getColumns(bulkActionType!)}
      modalDescription={
        <Trans
          i18nKey={`bulkActions.confirmationModal.modalDescription.${bulkActionType}`}
          values={{
            count: selectedRows.length,
          }}
          components={{ bold: <Text weight="semibold" /> }}
        />
      }
    />
  )
}

export default CoreBulkActionsConfirmationModal
