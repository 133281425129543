import { Input } from 'antd'
import { FC, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ColFilterKey,
  StringRangeFilter,
} from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnFilterConfig'
import styles from './TextInputRange.less'

export interface TextInputRangeProps {
  onChange: (newValue: StringRangeFilter) => void
  value: StringRangeFilter
  columnKey: ColFilterKey
}

const TextInputRange: FC<TextInputRangeProps> = ({
  onChange,
  columnKey,
  value,
}) => {
  const { t } = useTranslation()

  const { min, max } = value

  const onMinChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange({ min: e.target.value, max })
  }
  const onMaxChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange({ min, max: e.target.value })
  }

  return (
    <div className={styles.container} data-test-id="textRangeFilterInput">
      <Input
        onChange={onMinChange}
        placeholder={t('columnFilters.inputs.rangeInput.minPlaceholder')}
        value={min}
        tabIndex={1}
        className={styles.input}
        data-analytics-id={`column-filter-text-input-${columnKey}-min`}
        data-test-id="textRangeMinInput"
      />
      {t('columnFilters.inputs.rangeInput.inputSeparator')}
      <Input
        onChange={onMaxChange}
        placeholder={t('columnFilters.inputs.rangeInput.maxPlaceholder')}
        value={max}
        tabIndex={2}
        className={styles.input}
        data-analytics-id={`column-filter-text-input-${columnKey}-max`}
        data-test-id="textRangeMaxInput"
      />
    </div>
  )
}

export default TextInputRange
