import { FC } from 'react'
import {
  EntityTransferSelect,
  ENTITY_TYPE,
  useIsThirdGen,
  EntityTransferSelectThirdGen,
} from '@signifyd/components'

import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import { useTablePagination } from 'pages/InvestigationSearchPage/queries/useTablePagination'
import { useTableQueryParams } from 'pages/InvestigationSearchPage/queries/useTableQueryParams'

const CustomerSelect: FC = () => {
  const { query, setQuery } = useTableQueryParams()
  const { setPagination } = useTablePagination()
  const { selectedRowKeys } = useTableContext()

  const initiallySelectedCustomers =
    query.customerId?.filter((key): key is string => !!key) ?? []

  const isThirdGen = useIsThirdGen()

  if (isThirdGen) {
    return (
      <EntityTransferSelectThirdGen
        entityType={ENTITY_TYPE.CUSTOMER}
        onSave={(selectedKeys) => {
          setQuery({ customerId: selectedKeys })
          setPagination({ current: 1 })
        }}
        selectedKeys={initiallySelectedCustomers}
        disabled={!!selectedRowKeys.length}
      />
    )
  }

  return (
    <EntityTransferSelect
      entityType={ENTITY_TYPE.CUSTOMER}
      onSave={(selectedKeys) => {
        setQuery({ customerId: selectedKeys })
        setPagination({ current: 1 })
      }}
      selectedKeys={initiallySelectedCustomers}
      disabled={!!selectedRowKeys.length}
    />
  )
}

export default CustomerSelect
