import { FC } from 'react'
import { useQueryParams } from 'use-query-params'
import { DataPointFilter } from '@signifyd/components'
import useGetInvestigation from 'core/hooks/useGetInvestigation'
import { similarityParamsMap } from 'core/hooks/useSimilarityParams'
import { useTableQueryParams } from 'pages/InvestigationSearchPage/queries/useTableQueryParams'

const DataPointFilterContainer: FC = () => {
  const [{ similarityInvestigationId, similarityAttributes }, setQuery] =
    useQueryParams(similarityParamsMap)
  const { query: tableQueryParams } = useTableQueryParams()

  const { data: similarityInvestigation } = useGetInvestigation(
    similarityInvestigationId
  )

  if (!similarityInvestigation) {
    return null
  }

  const checkedSimilarityAttributes = similarityAttributes?.filter(
    (similarityAttribute) => !!similarityAttribute
  )

  const onViewConnectedOrders = (selectedDataPoints: Array<string>): void => {
    const formattedDataPoints = selectedDataPoints.map((selectedDataPoint) =>
      selectedDataPoint.toUpperCase().replace(' ', '_')
    )

    setQuery({
      similarityAttributes: formattedDataPoints,
    })
  }

  return (
    <DataPointFilter
      investigation={similarityInvestigation}
      dateRange={tableQueryParams?.normalizedPurchaseCreatedAt}
      onViewConnectedOrders={onViewConnectedOrders}
      selectedDataPoints={
        checkedSimilarityAttributes?.length
          ? (checkedSimilarityAttributes as Array<string>)
          : []
      }
      customerId={tableQueryParams?.customerId?.filter(
        (id): id is string => !!id
      )}
      teamId={tableQueryParams?.teamId?.filter((id): id is string => !!id)}
    />
  )
}

export default DataPointFilterContainer
