import { DownloadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import useGetInvestigations from 'pages/InvestigationSearchPage/containers/SearchResultsTable/useGetInvestigations'
import useDownloadSearchResults from 'pages/InvestigationSearchPage/hooks/useDownloadSearchResults'
import styles from './DownloadButton.less'

const DownloadButton: FC = () => {
  const { t } = useTranslation()
  const {
    downloadSearchResults,
    isBackgroundDownloadActive,
    isDownloadPending,
  } = useDownloadSearchResults()
  const { data } = useGetInvestigations()

  const isDownloadDisabled =
    isDownloadPending || isBackgroundDownloadActive || !data?.totalResults

  const tooltipTitle = isDownloadDisabled
    ? t('investigationSearch.downloadButton.unavailable')
    : undefined

  return (
    <Tooltip placement="left" title={tooltipTitle}>
      <Button
        type="link"
        className={styles.button}
        data-analytics-id="download-button"
        data-test-id="downloadButton"
        onClick={downloadSearchResults}
        disabled={isDownloadDisabled}
        loading={isDownloadPending}
      >
        {!isDownloadPending && <DownloadOutlined />}
        {t('investigationSearch.downloadButton.text')}
      </Button>
    </Tooltip>
  )
}

export default DownloadButton
