import { Popover } from 'antd'
import { FC, useState } from 'react'
import FilterButton from 'core/components/FilterButton'
import SearchColumnFilterContent from 'pages/InvestigationSearchPage/components/SearchColumnFilterContent'
import {
  ColFilterKey,
  COL_FILTERS,
  getFilterStateValue,
} from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnFilterConfig'
import { useTableQueryParams } from 'pages/InvestigationSearchPage/queries/useTableQueryParams'
import { TooltipPlacement } from 'antd/lib/tooltip'
import styles from './ColumnFilterPopover.less'

export interface PopoverProps {
  popoverSize?: 'medium' | 'large'
  popoverPlacement?: TooltipPlacement
}

export interface ColumnFilterProps extends PopoverProps {
  columnKey: ColFilterKey
}

const ColumnFilterPopover: FC<ColumnFilterProps> = ({
  columnKey,
  popoverSize,
  popoverPlacement = 'bottom',
}) => {
  const [visible, setVisible] = useState(false)

  const { query } = useTableQueryParams()

  const columnData = COL_FILTERS[columnKey]

  const filterValue = getFilterStateValue(columnData, query)

  const onPopoverVisibleChange = (localVisible: boolean): void => {
    setVisible(localVisible)
  }

  const handleClick = (): void => {
    setVisible(!visible)
  }

  return (
    <div className={styles.columnFilter}>
      <Popover
        content={
          <SearchColumnFilterContent
            size={popoverSize}
            columnKey={columnKey}
            filterValue={filterValue}
            hideActiveColumn={handleClick}
          />
        }
        placement={popoverPlacement}
        trigger="click"
        open={visible}
        onOpenChange={onPopoverVisibleChange}
        overlayClassName={styles.popover}
        destroyTooltipOnHide
      >
        <div>
          <FilterButton
            onClick={handleClick}
            selected={!!filterValue}
            columnKey={columnKey}
          />
        </div>
      </Popover>
    </div>
  )
}

export default ColumnFilterPopover
