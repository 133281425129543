import { FC } from 'react'
import {
  EntityTransferSelect,
  ENTITY_TYPE,
  EntityTransferSelectThirdGen,
  useIsThirdGen,
} from '@signifyd/components'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import { useTablePagination } from 'pages/InvestigationSearchPage/queries/useTablePagination'
import { useTableQueryParams } from 'pages/InvestigationSearchPage/queries/useTableQueryParams'

const TeamSelect: FC = () => {
  const { query, setQuery } = useTableQueryParams()
  const { setPagination } = useTablePagination()
  const { selectedRowKeys } = useTableContext()

  const initiallySelectedTeams =
    query.teamId?.filter((key): key is string => !!key) ?? []

  const isThirdGen = useIsThirdGen()

  if (isThirdGen) {
    return (
      <>
        <EntityTransferSelectThirdGen
          selectedKeys={initiallySelectedTeams}
          onSave={(selectedKeys) => {
            setQuery({ teamId: selectedKeys })
            setPagination({ current: 1 })
          }}
          entityType={ENTITY_TYPE.TEAM}
          disabled={!!selectedRowKeys.length}
        />
      </>
    )
  }

  return (
    <>
      <EntityTransferSelect
        selectedKeys={initiallySelectedTeams}
        onSave={(selectedKeys) => {
          setQuery({ teamId: selectedKeys })
          setPagination({ current: 1 })
        }}
        entityType={ENTITY_TYPE.TEAM}
        disabled={!!selectedRowKeys.length}
      />
    </>
  )
}

export default TeamSelect
