import { FC } from 'react'
import { SEARCH_ORDER } from '@signifyd/http'
import OrderIcon from 'core/components/OrderIcon'
import { COL_FILTERS } from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnFilterConfig'
import { useTableSorting } from 'pages/InvestigationSearchPage/queries/useTableSorting'

interface Props {
  columnKey: string
}

const SearchOrderIcon: FC<Props> = ({ columnKey }) => {
  const { query, setQuery } = useTableSorting()

  const columnData = COL_FILTERS[columnKey]

  // We do not currently support ordering on custom columns
  if (columnData.searchKey === 'custom') {
    return null
  }

  const currentSortColumn = query.orderBy

  const orderBy = columnData.searchKey

  const currentSortOrder =
    columnData.searchKey === currentSortColumn ? query.sortOrder : undefined

  const handleClick = (order: SEARCH_ORDER): void => {
    setQuery({
      orderBy,
      sortOrder: order,
    })
  }

  return (
    <OrderIcon
      onClick={handleClick}
      currentOrder={currentSortOrder}
      columnKey={columnData.searchKey}
    />
  )
}

export default SearchOrderIcon
