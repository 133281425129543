import { LoadingOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'
import { FloatButton, Table } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { InvestigationInfo } from '@signifyd/http'
import ResizeListener from 'core/components/ResizeListener'
import useSimilarityParams from 'core/hooks/useSimilarityParams'
import { TableRowSelection } from 'antd/es/table/interface'
import styles from './SearchResultsTable.less'
import useGetColumns from './useGetColumns'
import useGetInvestigations from './useGetInvestigations'
import useTableScroll from './useTableScroll'
import { useTableContext } from '../SearchContainer/TableProvider'

interface Props {
  bulkActionChange?: TableRowSelection<InvestigationInfo>
}

const SearchResultsTable: FC<Props> = ({ bulkActionChange }) => {
  const similarityParams = useSimilarityParams()

  const { columns } = useGetColumns()

  const { isFetching, data } = useGetInvestigations()

  const { scroll } = useTableScroll(data)

  const { tableContainer, tableRenderKey, rerenderTable, resetTableScroll } =
    useTableContext()

  const handleScrollToTop = async (): Promise<void> => {
    await resetTableScroll('searchBarContainer')
  }

  return (
    <div
      key={tableRenderKey}
      className={cx([styles.resultsTable, 'searchResultsTable'])}
      ref={tableContainer}
      data-test-id="searchResultsTable"
    >
      {/** Note that anything within this component will get re-rendered if the window is resized, so component state will be reset */}
      <Table
        scroll={scroll}
        pagination={false}
        columns={columns}
        rowKey={(record) => record.investigationId.toString()}
        rowClassName={({ investigationId }) =>
          cx(
            styles.rowStyle,
            investigationId === similarityParams?.similarityInvestigationId &&
              styles.similarity
          )
        }
        dataSource={data?.investigations}
        size="small"
        loading={{
          spinning: isFetching,
          indicator: <LoadingOutlined />,
        }}
        rowSelection={bulkActionChange}
      />
      {/* Forces the table to re-render when the screen resizes by changing the container key */}
      <ResizeListener onResize={rerenderTable} />
      <FloatButton
        icon={<VerticalAlignTopOutlined />}
        onClick={handleScrollToTop}
        data-test-id="scrollToTop"
        data-analytics-id="scroll-to-top"
        type="primary"
        className={styles.returnToTop}
      />
    </div>
  )
}

export default SearchResultsTable
