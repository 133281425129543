import { Button } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ReadMoreButton.less'

interface Props {
  onClick?: () => void
}

const ReadMoreButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()

  if (!onClick) {
    return null
  }

  return (
    <Button
      type="link"
      onClick={onClick}
      className={styles.button}
      data-test-id="readMoreButton"
      data-analytics-id="read-more-button"
    >
      {t('bulkActions.notification.readMore')}
    </Button>
  )
}

export default ReadMoreButton
