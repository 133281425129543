import {
  BarcodeOutlined,
  GlobalOutlined,
  MobileOutlined,
  PhoneOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import { FC } from 'react'
import {
  CloudOfflineIcon,
  IconProps,
  KioskIcon,
  PosTerminalIcon,
  SocialIcon,
  i18nInstance,
} from '@signifyd/components'
import { ORDER_CHANNEL } from '@signifyd/http'
import styles from './ChannelCell.less'

const channelToAntdIcon = new Map<ORDER_CHANNEL, FC<IconProps>>([
  [ORDER_CHANNEL.WEB, GlobalOutlined],
  [ORDER_CHANNEL.MOBILE_APP, MobileOutlined],
  [ORDER_CHANNEL.PHONE, PhoneOutlined],
  [ORDER_CHANNEL.MARKETPLACE, ShoppingCartOutlined],
  [ORDER_CHANNEL.SCAN_AND_GO, BarcodeOutlined],
])

const channelToCustomIconComponent = new Map<ORDER_CHANNEL, FC<IconProps>>([
  [ORDER_CHANNEL.IN_STORE_KIOSK, KioskIcon],
  [ORDER_CHANNEL.OFFLINE, CloudOfflineIcon],
  [ORDER_CHANNEL.POS, PosTerminalIcon],
  [ORDER_CHANNEL.SOCIAL, SocialIcon],
])

const ChannelCell: FC<{ channel: ORDER_CHANNEL }> = ({ channel }) => {
  const AntIcon = channelToAntdIcon.get(channel)

  if (AntIcon) {
    return (
      <AntIcon
        className={styles.channelIcon}
        title={i18nInstance.t(`icon.orderChannel.${channel}`)}
      />
    )
  }

  const CustomIconComponent = channelToCustomIconComponent.get(channel)

  if (CustomIconComponent) {
    return (
      <CustomIconComponent
        className={styles.channelIcon}
        title={i18nInstance.t(`icon.orderChannel.${channel}`)}
      />
    )
  }

  return null
}

export default ChannelCell
