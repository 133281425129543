import { Flex } from 'antd'
import { FC } from 'react'
import { TextThirdGen, useIsThirdGen } from '@signifyd/components'
import ColumnFilterPopover from 'pages/InvestigationSearchPage/components/ColumnFilterPopover'
import SearchOrderIcon from 'pages/InvestigationSearchPage/components/SearchOrderIcon/SearchOrderIcon'
import {
  COL_FILTERS,
  ColFilterKey,
} from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnFilterConfig'
import styles from './SearchTableHeader.less'
import { PopoverProps } from '../ColumnFilterPopover/ColumnFilterPopover'

export interface TableHeaderProps {
  title: string
  // TODO FET-1835 https://signifyd.atlassian.net/browse/FET-1835
  // Make this required after all columns are updated, if possible
  filterColumnKey?: ColFilterKey
  popoverOptions?: PopoverProps
}

const canOrder = (columnKey: ColFilterKey): boolean => {
  const columnData = COL_FILTERS[columnKey]
  if (columnData.searchKey === 'custom') {
    return false
  }

  return !!columnData.canOrderBy
}

const SearchTableHeader: FC<TableHeaderProps> = ({
  title,
  filterColumnKey,
  popoverOptions,
}) => {
  const hasOrderIcon = filterColumnKey && canOrder(filterColumnKey)
  const hasPopover = filterColumnKey && COL_FILTERS[filterColumnKey]?.TYPE
  const isThirdGen = useIsThirdGen()

  return (
    <Flex align="center" justify="space-between" className={styles.tableHeader}>
      {isThirdGen ? (
        <TextThirdGen size="sm">{title}</TextThirdGen>
      ) : (
        <span>{title}</span>
      )}
      <Flex align="center" justify="space-between">
        {hasOrderIcon && <SearchOrderIcon columnKey={filterColumnKey} />}
        {hasPopover && (
          <ColumnFilterPopover
            columnKey={filterColumnKey}
            {...popoverOptions}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default SearchTableHeader
