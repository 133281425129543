import { Button } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './TruncatedTextView.less'

interface Props {
  text: string
}

const DISPLAY_LIMIT = 20

const TruncatedTextView: FC<Props> = ({ text }) => {
  const [isTruncated, setIsTruncated] = useState(true)

  const { t } = useTranslation()

  return (
    <div>
      <span className={styles.textContainer}>
        {isTruncated ? `${text.substring(0, DISPLAY_LIMIT)}...` : text}
      </span>
      <Button
        className={styles.toggleTruncateBtn}
        onClick={() => setIsTruncated(!isTruncated)}
        type="link"
      >
        {isTruncated
          ? t('investigationSearch.truncatedTextView.view')
          : t('investigationSearch.truncatedTextView.hide')}
      </Button>
    </div>
  )
}

export default TruncatedTextView
