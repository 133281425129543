import { Button } from 'antd'
import { kebabCase } from 'lodash'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from 'antd/lib/button'
import bulkActions from 'locales/en-US/bulkActions'
import styles from './BulkActionsButton.less'

interface BulkActionsButtonProps extends ButtonProps {
  buttonKey: keyof (typeof bulkActions)['buttons']
  buttonClass?: string
  Icon: ReactNode
}

const BulkActionsButton: FC<BulkActionsButtonProps> = ({
  Icon,
  buttonKey,
  buttonClass,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Button
      {...rest}
      className={buttonClass || styles.bulkActionButton}
      onClick={onClick}
      data-analytics-id={`bulk-actions-${kebabCase(buttonKey)}`}
      data-test-id={buttonKey}
    >
      {Icon}
      {t(`bulkActions.buttons.${buttonKey}`)}
    </Button>
  )
}

export default BulkActionsButton
