import { Popover } from 'antd'
import { FC, ReactNode } from 'react'
import MultiComponentPopoverButton from './MultiComponentPopoverButton'

interface MultiIconPopoverProps {
  components: Array<ReactNode>
  title: string
}

const MultiComponentPopover: FC<MultiIconPopoverProps> = ({
  components,
  title,
}) => {
  const [firstItemValue] = components

  if (!firstItemValue) {
    return null
  }

  const popoverContent = components.map((value, index): ReactNode => {
    return <div key={index}>{value}</div>
  })

  return (
    <>
      {firstItemValue}
      {components.length > 1 && (
        <span data-test-id="multipleIconPopover">
          <Popover
            placement="topLeft"
            title={title}
            content={popoverContent}
            trigger="hover"
          >
            <span>
              <MultiComponentPopoverButton amount={components.length - 1} />
            </span>
          </Popover>
        </span>
      )}
    </>
  )
}

export default MultiComponentPopover
