import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { InvestigationInfo } from '@signifyd/http'
import { useBulkActionsContext } from 'pages/InvestigationSearchPage/components/BulkActions/BulkActionsProvider'
import { BulkActionType } from 'pages/InvestigationSearchPage/components/BulkActions/types'
import { EvaluatedInvestigation } from './types'
import {
  evaluateInvestigationsForGuarantee,
  evaluateInvestigationsForThumbsDown,
} from './utils'

export interface InvestigationEvaluation {
  evaluatedInvestigations: Array<EvaluatedInvestigation>
  selectedAndValidIds: Array<string>
}

export const evaluateInvestigations = async (
  investigations: Array<InvestigationInfo>,
  bulkActionType: BulkActionType
): Promise<Array<EvaluatedInvestigation>> => {
  if (bulkActionType === 'SUBMIT_FOR_GUARANTEE') {
    return evaluateInvestigationsForGuarantee(investigations)
  }

  if (bulkActionType === 'THUMBS_DOWN') {
    return Promise.resolve(evaluateInvestigationsForThumbsDown(investigations))
  }

  return Promise.resolve(
    investigations.map((investigation) => ({
      investigation,
    }))
  )
}

export const useGetEvaluatedInvestigations =
  (): UseQueryResult<InvestigationEvaluation> => {
    const { selectedInvestigations: investigations, bulkActionType } =
      useBulkActionsContext()

    return useQuery<InvestigationEvaluation>(
      ['evaluatedInvestigations', investigations, bulkActionType],
      async () => {
        const evaluatedInvestigations = await evaluateInvestigations(
          investigations,
          bulkActionType!
        )

        const selectedAndValidIds = evaluatedInvestigations
          .filter((evaluation) => !evaluation.disabledReason)
          .map(({ investigation: { investigationId } }) =>
            investigationId.toString()
          )

        return { evaluatedInvestigations, selectedAndValidIds }
      },
      { enabled: !!investigations.length && !!bulkActionType }
    )
  }
