import { Alert, Flex, Row } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingMD } from '@signifyd/ant'
import { Space, useIsThirdGen } from '@signifyd/components'
import { UserData } from 'core/hooks/useGetUserData'
import DataPointFilterContainer from 'pages/InvestigationSearchPage/components/DataPointFilter'
import ResetButton from 'pages/InvestigationSearchPage/components/ResetButton'
import SearchBar from 'pages/InvestigationSearchPage/components/SearchBar'
import SearchDatePicker from 'pages/InvestigationSearchPage/components/SearchDatePicker/SearchDatePicker'
import SearchResults from 'pages/InvestigationSearchPage/components/SearchResults/SearchResults'
import SearchTypeSwitch from 'pages/InvestigationSearchPage/components/SearchTypeSwitch/SearchTypeSwitch'
import SimilarityParamTag from 'pages/InvestigationSearchPage/components/SimilarityParamTag'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import useHasSecondaryMatches from 'pages/InvestigationSearchPage/hooks/useHasSecondaryMatches/useHasSecondaryMatches'

interface Props {
  userData: UserData
}

const SearchBarContainer: FC<Props> = ({ userData }) => {
  const { t } = useTranslation()
  const { selectedRowKeys } = useTableContext()
  const isDisabled = !!selectedRowKeys.length
  const isThirdGen = useIsThirdGen()
  const hasSecondaryMatches = useHasSecondaryMatches()

  return (
    <div id="searchBarContainer">
      {isDisabled && (
        <>
          <Space size="md" />
          <Alert
            message=""
            description={t('investigationSearch.disabledSearch')}
            type="warning"
            data-test-id="disabledSearchAlertMessage"
            showIcon
          />
        </>
      )}
      {!isThirdGen && <Space size="md" />}
      <SearchBar />
      <Space size="md" />
      <Row justify="space-between">
        <Flex gap={spacingMD} align="center" wrap>
          <SearchResults />
          <SearchDatePicker userData={userData} />
          <SearchTypeSwitch />
        </Flex>
        <Flex gap={spacingMD} align="center" wrap>
          {!hasSecondaryMatches && <SimilarityParamTag />}
          <ResetButton />
        </Flex>
      </Row>
      <Space size="md" />
      {hasSecondaryMatches && <DataPointFilterContainer />}
    </div>
  )
}

export default SearchBarContainer
