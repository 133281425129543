import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PREDICTION_LIST_TYPE, PredictionList } from '@signifyd/http'
import { SelectDataSourceModal } from './AddToList/SelectDataSourceModal'
import SelectListModal from './AddToList/SelectListModal'
import { SelectListTypeModal } from './AddToList/SelectListTypeModal'
import BulkActionsAddToListNotesModalContent from './BulkActionsNotesModal/BulkActionsAddToListNotesModalContent'
import BulkActionsNotesModal from './BulkActionsNotesModal/BulkActionsNotesModal'
import { useBulkActionsContext } from './BulkActionsProvider'
import AddToListConfirmationModal from './flows/AddToList/AddToListConfirmationModal/AddToListConfirmationModal'
import { DataSource } from './types'

const listTypesWithMultipleSources = [
  PREDICTION_LIST_TYPE.EMAIL,
  PREDICTION_LIST_TYPE.ADDRESS,
]

const AddToListFlow: FC = () => {
  const { t } = useTranslation()

  const { setBulkActionStage, setBulkActionType, bulkActionStage, setNote } =
    useBulkActionsContext()

  const [listType, setListType] = useState<PREDICTION_LIST_TYPE>()
  const [dataSource, setDataSource] = useState<DataSource>()
  const [selectedList, setSelectedList] = useState<PredictionList>()

  return (
    <>
      <SelectListTypeModal
        onNext={(listType) => {
          setListType(listType)

          if (listTypesWithMultipleSources.includes(listType)) {
            setBulkActionStage('SELECT_VALUE_DATASOURCE')
          } else {
            setBulkActionStage('SELECT_LIST')
          }
        }}
        onPrevious={() => {
          setBulkActionType(undefined)
        }}
      />
      <SelectDataSourceModal
        onNext={(dataSource: DataSource) => {
          setDataSource(dataSource)
          setBulkActionStage('SELECT_LIST')
        }}
        onPrevious={() => {
          setBulkActionStage('SELECT_LIST_TYPE')
        }}
        isVisible={bulkActionStage === 'SELECT_VALUE_DATASOURCE'}
        dataType={listType === PREDICTION_LIST_TYPE.EMAIL ? 'email' : 'address'}
      />
      {!!listType && (
        <SelectListModal
          predictionListType={listType}
          onNext={(list) => {
            setSelectedList(list)
            setBulkActionStage('NOTES')
          }}
          onPrevious={() => {
            const hasSubType = listTypesWithMultipleSources.includes(listType)

            setBulkActionStage(
              hasSubType ? 'SELECT_VALUE_DATASOURCE' : 'SELECT_LIST_TYPE'
            )
          }}
        />
      )}
      {!!selectedList && (
        <>
          <BulkActionsNotesModal
            onNext={() => {
              setBulkActionStage('CONFIRMATION')
            }}
            onPrevious={() => {
              setNote('')
              setBulkActionStage('SELECT_LIST')
            }}
            isVisible={bulkActionStage === 'NOTES'}
            cancelText={t('bulkActions.bulkAddToList.modal.backButton')}
          >
            <BulkActionsAddToListNotesModalContent
              selectedList={selectedList}
              dataSource={dataSource}
            />
          </BulkActionsNotesModal>
          <AddToListConfirmationModal
            predictionListType={selectedList.type}
            selectedList={selectedList}
            dataSource={dataSource}
          />
        </>
      )}
    </>
  )
}

export default AddToListFlow
