import { message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DOWNLOAD_COLUMN, getExportToken } from '@signifyd/http'
import useGetInvestigationSearchContext from 'core/hooks/useGetInvestigationSearchContext'
import useGetUserData from 'core/hooks/useGetUserData'
import useGetInvestigations from 'pages/InvestigationSearchPage/containers/SearchResultsTable/useGetInvestigations'
import { useTeamCustomerIdQueryParams } from 'pages/InvestigationSearchPage/queries/useTeamCustomerIdQueryParams'
import { ADMIN_FIELDS } from './downloadFields'

interface UseDownloadSearchResultsHook {
  downloadSearchResults: () => void
  isBackgroundDownloadActive: boolean
  isDownloadPending: boolean
}

const useDownloadSearchResults = (): UseDownloadSearchResultsHook => {
  const { t } = useTranslation()
  const [isDownloadPending, setIsDownloadPending] = useState(false)
  const [isBackgroundDownloadActive, setIsBackgroundDownloadActive] =
    useState(false)
  const { data } = useGetInvestigations()
  const { currentUser, isAdmin } = useGetUserData()
  const params = useTeamCustomerIdQueryParams()
  const { data: searchContextData } = useGetInvestigationSearchContext(params)
  const flexibleSearchQuery = data?.flexibleSearchQuery
  const timeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [timeout])

  const startTimeout = (): void => {
    timeout.current = setTimeout(() => {
      setIsBackgroundDownloadActive(false)
    }, 10000)
  }

  const downloadSearchResults = async (): Promise<void> => {
    if (!flexibleSearchQuery) {
      return
    }

    if (searchContextData) {
      flexibleSearchQuery.columns = [
        searchContextData.hasAirlineOrders
          ? DOWNLOAD_COLUMN.POWERSEARCH_AIRLINE
          : DOWNLOAD_COLUMN.POWERSEARCH,
      ]

      if (isAdmin) {
        flexibleSearchQuery.fields = ADMIN_FIELDS
      }
    }

    try {
      setIsDownloadPending(true)

      const {
        data: { token },
      } = await getExportToken(flexibleSearchQuery)

      setIsDownloadPending(false)
      startTimeout()

      const link = document.createElement('a')

      link.href = `${BASE_URL}/v2/investigations/searchExport/${currentUser.userId}?token=${token}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setIsBackgroundDownloadActive(true)
    } catch {
      message.error(t('investigationSearch.downloadButton.failure'))
      setIsDownloadPending(false)
    }
  }

  return {
    downloadSearchResults,
    isDownloadPending,
    isBackgroundDownloadActive,
  }
}

export default useDownloadSearchResults
