import { UseQueryResult } from '@tanstack/react-query'
import { message } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useSimilarityParams from 'core/hooks/useSimilarityParams'
import useGetSearchResults from 'pages/InvestigationSearchPage/hooks'
import { SearchResultsResponse } from 'pages/InvestigationSearchPage/hooks/useGetSearchResults'
import useGetSimilaritySearchResults from 'pages/InvestigationSearchPage/hooks/useGetSimilaritySearchResults'

const useGetInvestigations = (): UseQueryResult<SearchResultsResponse> => {
  const { t } = useTranslation()

  const similarity = useSimilarityParams()

  const investigations = useGetSearchResults()
  const linkedInvestigations = useGetSimilaritySearchResults()

  useEffect(() => {
    if (investigations.isError || linkedInvestigations.isError) {
      message.destroy()
      message.error(t('investigationSearch.searchFailure'))
    }
  }, [investigations.isError, linkedInvestigations.isError, t])

  const searchInvestigations = similarity
    ? linkedInvestigations
    : investigations

  return searchInvestigations
}

export default useGetInvestigations
