import { Tag } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import useSimilarityParams from 'core/hooks/useSimilarityParams'
import styles from './SimilarityParamTag.less'

declare enum SIMILARITY_FIELD {
  BILLING_ADDRESS = 'BILLING_ADDRESS',
  CONFIRMATION_EMAIL = 'CONFIRMATION_EMAIL',
  CONFIRMATION_PHONE = 'CONFIRMATION_PHONE',
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
  DEVICE_ID = 'DEVICE_ID',
  IP_ADDRESS = 'IP_ADDRESS',
}

const SimilarityParamTag: FC = () => {
  const { t } = useTranslation()
  const similarity = useSimilarityParams()

  if (!similarity?.similarityAttributes.length) {
    return null
  }

  const { similarityAttributes } = similarity
  const [firstSimilarity] = similarityAttributes

  return (
    <Tag
      className={styles.tag}
      data-test-id={`similarityTag-${firstSimilarity}`}
    >
      {t('similarity.SIM')}:{' '}
      {t(`similarity.${firstSimilarity as unknown as SIMILARITY_FIELD}`)}
    </Tag>
  )
}

export default SimilarityParamTag
