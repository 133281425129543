import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { Flex } from 'antd'
import { FC } from 'react'
import { spacingMD } from '@signifyd/ant'
import { ColumnProps } from '../../ThirdGenColumnDrawer'
import Draggable from './Draggable'
import Droppable from './Droppable'
import ItemLayout from './ItemLayout'

enum ORDER {
  UP = 'up',
  DOWN = 'down',
}

interface Props {
  draggableColumns: Array<ColumnProps>
  setDisplayColumns: (columns: Array<ColumnProps>) => void
  hideColumn: (columnName: ColumnProps) => void
  loading: boolean
}

const ThirdGenDynamicItems: FC<Props> = ({
  draggableColumns,
  setDisplayColumns,
  hideColumn,
  loading,
}) => {
  const visibleColumns = draggableColumns.filter((column) => !column.hidden)

  const handleOrdering = (position: number, ordering: ORDER): void => {
    const addPosition = ordering === ORDER.UP ? position - 1 : position + 1

    if (position === -1) {
      return
    }

    const movedColumn = draggableColumns[position]

    const removedColumns = draggableColumns.toSpliced(position, 1)
    const updated = removedColumns.toSpliced(addPosition, 0, movedColumn)

    setDisplayColumns(updated)
  }

  return (
    <SortableContext items={draggableColumns} strategy={rectSortingStrategy}>
      <Flex gap={spacingMD} vertical data-test-id="visibleColumns">
        {visibleColumns.map((column, position) => {
          const { name, id } = column

          return (
            <Droppable id={name} key={name}>
              <Draggable id={id}>
                <ItemLayout
                  loading={loading}
                  column={column}
                  position={position}
                  moveUpDisabled={position === 0}
                  moveDownDisabled={position === visibleColumns.length - 1}
                  handleOrdering={handleOrdering}
                  toggleColumn={hideColumn}
                />
              </Draggable>
            </Droppable>
          )
        })}
      </Flex>
    </SortableContext>
  )
}

export default ThirdGenDynamicItems
