import { Button, Divider, Form } from 'antd'
import cx from 'classnames'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import {
  ColFilterKey,
  COL_FILTERS,
  FilterStateValues,
  getQueryFromFilterState,
} from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnFilterConfig'
import { useTablePagination } from 'pages/InvestigationSearchPage/queries/useTablePagination'
import { useTableQueryParams } from 'pages/InvestigationSearchPage/queries/useTableQueryParams'
import FilterInput from './FilterInput'
import styles from './SearchColumnFilterContent.less'

export interface ColumnFilterContentProps {
  size?: 'medium' | 'large'
  columnKey: ColFilterKey
  filterValue: FilterStateValues
  hideActiveColumn: () => void
}

const SearchColumnFilterContent: FC<ColumnFilterContentProps> = (props) => {
  const { t } = useTranslation()

  const { setQuery } = useTableQueryParams()
  const { setPagination } = useTablePagination()

  const { size, columnKey, filterValue, hideActiveColumn } = props

  const [internalFilterValue, setInternalFilterValue] =
    useState<FilterStateValues>(filterValue)

  const onFilterValueChange = (newFilterValue: FilterStateValues): void => {
    setInternalFilterValue(newFilterValue)
  }

  const { saveCurrentColumnPosition, rerenderTable } = useTableContext()

  const onSubmit = (): void => {
    // Changing a filter will start a "new" search at page 1
    setPagination({ current: 1 })

    saveCurrentColumnPosition()

    setQuery(
      getQueryFromFilterState(internalFilterValue, COL_FILTERS[columnKey])
    )

    hideActiveColumn()

    rerenderTable()
  }

  const handleReset = (): void => {
    setInternalFilterValue('')
  }

  return (
    <Form className={styles.popoverForm}>
      <Form.Item
        className={cx(styles.columnFilterContent, size && styles[size])}
      >
        <FilterInput
          {...props}
          onSubmit={onSubmit}
          internalFilterValue={internalFilterValue}
          onFilterValueChange={onFilterValueChange}
        />
      </Form.Item>
      <Divider className={styles.divider} />
      <div className={styles.actionBtns}>
        <Button
          size="small"
          type="link"
          onClick={handleReset}
          data-analytics-id={`column-filter-${columnKey}-reset-button`}
          data-test-id="resetButton"
        >
          {t('columnFilters.resetButton')}
        </Button>
        <Button
          size="small"
          type="link"
          onClick={onSubmit}
          data-analytics-id={`column-filter-${columnKey}-apply-button`}
          data-test-id="applyButton"
        >
          {t('columnFilters.applyButton')}
        </Button>
      </div>
    </Form>
  )
}

export default SearchColumnFilterContent
