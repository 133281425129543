import moment from 'moment-timezone'
import { FC } from 'react'
import useGetUserData from 'core/hooks/useGetUserData'

export interface FormatDateProps {
  UTCTimestamp: string
  format?: string
  showTimeZoneAbbr?: boolean
}

const FormatDate: FC<FormatDateProps> = ({
  UTCTimestamp,
  format = 'MM-DD-YY',
  showTimeZoneAbbr = false,
}) => {
  const { currentUser } = useGetUserData()

  const timeZone = currentUser.uiState?.timeZone?.name
  const localTime = moment.utc(UTCTimestamp).tz(timeZone || moment.tz.guess())
  const formattedTime = localTime.format(format)
  const zoneAbbr = showTimeZoneAbbr ? localTime.zoneAbbr() : ''

  return (
    <span>
      {UTCTimestamp ? formattedTime : ''}
      {zoneAbbr ? ` ${zoneAbbr}` : ''}
    </span>
  )
}

export default FormatDate
