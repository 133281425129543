import { Flex, Tooltip, Skeleton, Button } from 'antd'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingMD } from '@signifyd/ant'
import { colorYonder } from '@signifyd/colors'
import {
  Text,
  GripLines,
  SquareChevronUp,
  SquareChevronDown,
  TextThirdGen,
} from '@signifyd/components'
import investigationSearchColumns from 'locales/en-US/investigationSearch'
import { ColumnProps } from '../../ThirdGenColumnDrawer'
import styles from './ItemLayout.less'

const TRUNCATE_LENGTH = 35

enum ORDER {
  UP = 'up',
  DOWN = 'down',
}

type ColumnNames = keyof (typeof investigationSearchColumns)['column']

interface Props {
  loading: boolean
  column: ColumnProps
  moveUpDisabled: boolean
  moveDownDisabled: boolean
  toggleColumn: (column: ColumnProps) => void
  position?: number
  handleOrdering?: (position: number, ordering: ORDER) => void
}

const ItemLayout: FC<Props> = ({
  loading,
  column,
  position,
  moveUpDisabled,
  moveDownDisabled,
  toggleColumn,
  handleOrdering,
}) => {
  const { t } = useTranslation()
  const { name, hidden } = column

  const renderTitle = (title: string): ReactElement => {
    const shouldTruncate = title.length > TRUNCATE_LENGTH

    return shouldTruncate ? (
      <Tooltip
        title={<Text className={styles.tooltipText}>{title}</Text>}
        placement="right"
      >
        <TextThirdGen paragraph className={styles.itemText}>
          {title}
        </TextThirdGen>
      </Tooltip>
    ) : (
      <Text>{title}</Text>
    )
  }

  const title = t(`investigationSearch.column.${name as ColumnNames}`)

  if (loading) {
    return <Skeleton.Input key={name} size="large" block active />
  }

  const dataIdPrefix = hidden ? 'show' : 'hide'

  return (
    <Flex
      align="center"
      justify="space-between"
      className={styles.itemContainer}
      gap={spacingMD}
    >
      <Flex gap={spacingMD}>
        <GripLines className={styles.grabIcon} fill={colorYonder} />
        <Flex align="center">
          <button
            data-test-id={`orderDownIcon-${name}`}
            data-analytics-id={`order-Down-${name}`}
            disabled={moveDownDisabled}
            className={styles.orderButton}
            onClick={() => position && handleOrdering?.(position, ORDER.DOWN)}
          >
            <SquareChevronDown
              fill={moveDownDisabled ? colorYonder : 'currentColor'}
            />
          </button>
          <button
            data-test-id={`orderUpIcon-${name}`}
            data-analytics-id={`order-up-${name}`}
            disabled={moveUpDisabled}
            className={styles.orderButton}
            onClick={() => position && handleOrdering?.(position, ORDER.UP)}
          >
            <SquareChevronUp
              fill={moveUpDisabled ? colorYonder : 'currentColor'}
            />
          </button>
        </Flex>
        {renderTitle(title)}
      </Flex>
      <Button
        type="text"
        data-analytics-id={`${dataIdPrefix}-drawer-button-${name}`}
        data-test-id={`${dataIdPrefix}DrawerButton-${name}`}
        onClick={() => toggleColumn(column)}
      >
        {hidden ? t(`columnDrawer.showColumn`) : t(`columnDrawer.hideColumn`)}
      </Button>
    </Flex>
  )
}

export default ItemLayout
