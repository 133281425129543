import { Tooltip } from 'antd'
import { FC } from 'react'
import { ExternalLink } from '@signifyd/components'
import { InvestigationInfo } from '@signifyd/http'
import FormatDate from 'core/components/FormatDate'
import getExternalConsoleUrl from 'core/utils/getExternalConsoleUrl'

interface Props {
  rowData: InvestigationInfo
  isCustomer: boolean
}

const DateTimeCell: FC<Props> = ({ rowData, isCustomer }) => {
  // Both normalizedPurchaseCreatedAt and createdAt are UTC string
  // Example: 2017-09-08T17:15:01+0000
  const date = rowData.normalizedPurchaseCreatedAt || rowData.createdAt
  const caseId = rowData.investigationId

  const url = getExternalConsoleUrl({ caseId: caseId.toString(), isCustomer })

  if (!date) {
    return null
  }

  return (
    <div data-test-id="dateTimeCell">
      <Tooltip title={`Open Case ${caseId}`}>
        <ExternalLink data-test-id={`searchLinkDate${caseId}`} url={url}>
          <FormatDate UTCTimestamp={date} format="YYYY-MM-DD" />
          <br />
          <FormatDate UTCTimestamp={date} format="h:mmA" showTimeZoneAbbr />
        </ExternalLink>
      </Tooltip>
    </div>
  )
}

export default DateTimeCell
