import { Flex, Tag, Tooltip } from 'antd'
import cx from 'classnames'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { colorJade20, colorGlitter } from '@signifyd/colors'
import { Text } from '@signifyd/components'
import { similarityParamsMap } from 'core/hooks/useSimilarityParams'
import styles from './SimilarityMatchTypes.less'

interface SimilarityMatchTagProps {
  tooltipTitle: string
  className: string
  tagText: string
  color: string
}

const SimilarityMatchTag = ({
  tooltipTitle,
  className,
  tagText,
  color,
}: SimilarityMatchTagProps): ReactElement => (
  <Tooltip
    title={tooltipTitle}
    placement="topLeft"
    data-analytics-id={`${tagText}-matches-tooltip`}
  >
    <Tag
      className={cx([styles.similarityTypeTag, className])}
      color={color}
      bordered
    >
      <Text size="sm">{tagText}</Text>
    </Tag>
  </Tooltip>
)

const SimilarityMatchTypes: FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'similarity.matchTag',
  })

  const [query] = useQueryParams(similarityParamsMap)
  const { similarityInvestigationId } = query

  if (!similarityInvestigationId) {
    return null
  }

  const tags = [
    {
      tooltipTitle: t('tooltip.direct'),
      className: styles.direct,
      tagText: t('direct'),
      color: colorJade20,
    },
    {
      tooltipTitle: t('tooltip.indirect'),
      className: styles.indirect,
      tagText: t('indirect'),
      color: colorGlitter,
    },
  ]

  return (
    <Flex
      data-test-id="similarityMatchTypeContainer"
      className={styles.similarityTagContainer}
    >
      {tags.map((tag) => (
        <SimilarityMatchTag key={tag.tooltipTitle} {...tag} />
      ))}
    </Flex>
  )
}

export default SimilarityMatchTypes
