import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { message } from 'antd'
import axios from 'axios'
import { partition } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  BULK_ACTION_OUTCOME_RESULT,
  bulkAddToList,
  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION,
  InvestigationValue,
  performBulkAttach,
  PredictionListTypeAddToList,
} from '@signifyd/http'
import { useBulkActionsContext } from 'pages/InvestigationSearchPage/components/BulkActions/BulkActionsProvider'
import { handleBulkActionNotifications } from 'pages/InvestigationSearchPage/hooks/usePerformBulkActions/bulkActionsNotifications'

interface Payload {
  listId: number
  duplicateEntryAction: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
  type: PredictionListTypeAddToList
  investigationValues: Array<InvestigationValue>
}

interface MutationConfig {
  onRejectionWithDuplicates: () => void
}

const useBulkAddToList = ({
  onRejectionWithDuplicates,
}: MutationConfig): UseMutationResult<void, unknown, Payload> => {
  const {
    note,
    setFailedOutcomes,
    fileAttacher,
    onReset,
    bulkActionType,
    setBulkActionStage,
  } = useBulkActionsContext()

  const { t } = useTranslation()

  return useMutation({
    mutationFn: async ({
      listId,
      duplicateEntryAction,
      type,
      investigationValues,
    }: Payload) => {
      try {
        const {
          data: { outcomes: allOutcomes },
        } = await bulkAddToList({
          duplicateEntryAction,
          listId,
          investigationValues,
          type,
          note,
        })

        const [failedOutcomes, successOutcomes] = partition(
          allOutcomes,
          ({ result }) => result === BULK_ACTION_OUTCOME_RESULT.FAILED
        )

        setFailedOutcomes(failedOutcomes)

        handleBulkActionNotifications(
          bulkActionType!,
          { allOutcomes, failedOutcomes, successOutcomes },
          () => setBulkActionStage('READ_MORE')
        )

        const noteIds = successOutcomes.map((item) => item.noteId)

        // An empty array of success outcomes means that there was no failure but no change. This can occur for instance
        // when bulk adding to list and choosing to discard duplicates
        if (!noteIds.length) {
          onReset()

          return
        }

        const { files } = fileAttacher

        await Promise.all(
          files.map(async (file) => {
            await performBulkAttach({
              file,
              noteIds,
              fileName: file.name,
            })
          })
        )

        onReset()
      } catch (e) {
        console.error(e)

        if (axios.isAxiosError(e) && e.response?.status === 409) {
          onRejectionWithDuplicates()
        } else {
          throw new Error('error adding to list')
        }
      }
    },
    onError: () => {
      message.error(t('bulkActions.bulkAddToList.addToListFailure'))
    },
  })
}

export default useBulkAddToList
