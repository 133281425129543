import { LockFilled } from '@ant-design/icons'
import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import type { DefaultColumns } from 'core/constants'
import { ColumnProps } from '../../ColumnDrawer'
import styles from './LockedItems.less'

interface Props {
  lockedColumns: Array<ColumnProps>
}

const LockedItems: FC<Props> = ({ lockedColumns }) => {
  const { t } = useTranslation()

  return (
    <Flex gap={8} vertical>
      {lockedColumns.map((column) => {
        const columnName = column.name as DefaultColumns

        return (
          <Flex
            align="center"
            gap={8}
            className={styles.itemContainer}
            key={column.id}
          >
            <LockFilled
              style={{ fontSize: 20 }}
              data-test-id={`columnLocked-${column.id}`}
              className={styles.padlock}
            />
            <Text className={styles.lockedItem}>
              {t(`columnDrawer.defaultColumns.${columnName}`)}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default LockedItems
