import { Button } from 'antd'
import { FC } from 'react'
import { useIsThirdGen } from '@signifyd/components'
import styles from './MultiComponentPopover.less'

interface Props {
  amount: number
}

const MultiComponentPopoverButton: FC<Props> = ({ amount }) => {
  const isThirdGen = useIsThirdGen()

  return (
    <Button
      className={styles.componentPopoverBtn}
      shape="circle"
      size={isThirdGen ? 'small' : 'middle'}
    >
      +{amount}
    </Button>
  )
}

export default MultiComponentPopoverButton
