import { Layout } from 'antd'
import { FC } from 'react'
import { Layout as SigLayout, useIsThirdGen } from '@signifyd/components'
import { UserData } from 'core/hooks/useGetUserData'
import SearchContainer from 'pages/InvestigationSearchPage/containers/SearchContainer'
import NavigationWrapper from './components/NavigationWrapper/NavigationWrapper'
import SearchTitleBar from './components/SearchTitleBar'
import ThirdGenNavBar from './components/ThirdGenNavBar/ThirdGenNavBar'
import SearchBarContainer from './containers/SearchBarContainer'
import { TableProvider } from './containers/SearchContainer/TableProvider'
import styles from './InvestigationSearchPage.less'

interface Props {
  userData: UserData
}

const { Content: SigContent, NavBar: SigNavBar, Header: SigHeader } = SigLayout
const { Content, Header } = Layout

const InvestigationSearchPage: FC<Props> = ({ userData }: Props) => {
  const isThirdGen = useIsThirdGen()

  if (isThirdGen) {
    return (
      <TableProvider>
        <Layout
          className={styles.investigationSearchPage}
          data-test-id="investigationSearchPage"
        >
          <ThirdGenNavBar userData={userData} />
          <Content>
            <Header className={styles.header}>
              <SearchTitleBar userData={userData} />
            </Header>
            <Content className={styles.content}>
              <SearchBarContainer userData={userData} />
              <SearchContainer />
            </Content>
          </Content>
        </Layout>
      </TableProvider>
    )
  }

  return (
    <TableProvider>
      <SigLayout data-test-id="investigationSearchPage">
        <SigNavBar>
          <NavigationWrapper userData={userData} />
        </SigNavBar>
        <SigHeader
          paddingTop="none"
          paddingBottom="none"
          marginBottom="none"
          className={styles.searchHeading}
          innerMaxWidth={null}
        >
          <SearchTitleBar userData={userData} />
        </SigHeader>
        <SigContent innerStyle={{ height: '100%' }} innerMaxWidth={null}>
          <SearchBarContainer userData={userData} />
          <SearchContainer />
        </SigContent>
      </SigLayout>
    </TableProvider>
  )
}

export default InvestigationSearchPage
