import bulkActions from './bulkActions'
import columnDrawer from './columnDrawer'
import columnFilters from './columnFilters'
import dynamicFilters from './dynamicFilters'
import icon from './icon'
import investigationSearch from './investigationSearch'
import searchBar from './searchBar'
import similarity from './similarity'

export default {
  investigationSearch,
  columnFilters,
  searchBar,
  dynamicFilters,
  similarity,
  icon,
  bulkActions,
  columnDrawer,
}
