import { Button, ButtonProps, Flex, Popconfirm } from 'antd'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingSM, spacingXS } from '@signifyd/ant'
import { colorGold } from '@signifyd/colors'
import {
  CircleExclamation,
  Space,
  TextThirdGen,
  TitleThirdGen,
} from '@signifyd/components'
import styles from './ThirdGenColumnDrawer.less'

const cancelButtonProps = {
  size: 'small',
  type: 'link',
} as unknown as ButtonProps

const okButtonProps = {
  size: 'small',
  type: 'primary',
  className: styles.okButton,
} as unknown as ButtonProps

interface Props {
  onClear: () => void
  onApply: () => void
  resetToDefault: () => void
  onCancel: () => void
  hasChanges: boolean
  loading: boolean
}

const ThirdGenColumnDrawerFooter: FC<Props> = ({
  loading,
  hasChanges,
  onClear,
  onApply,
  resetToDefault,
  onCancel,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'columnDrawer' })

  const popOverTitle = (title: string, subText: string): ReactElement => (
    <Flex gap={spacingSM} data-test-id="drawerResetConfirm">
      <CircleExclamation fill={colorGold} className={styles.warningIcon} />
      <div>
        <TitleThirdGen level={3}>{title}</TitleThirdGen>
        <Space size="xs" />
        <TextThirdGen weight="normal" className={styles.subtext}>
          {subText}
        </TextThirdGen>
      </div>
    </Flex>
  )

  return (
    <Flex align="center" justify="space-between">
      <Popconfirm
        overlayClassName="sig-popconfirm"
        icon={null}
        title={popOverTitle(
          t('resetToDefault.title'),
          t('resetToDefault.subtext')
        )}
        onConfirm={resetToDefault}
        okText={t('resetToDefault.reset')}
        cancelText={t('resetToDefault.keep')}
        cancelButtonProps={{
          ...cancelButtonProps,
          'data-analytics-id': 'keep-drawer-button',
        }}
        okButtonProps={{
          ...okButtonProps,
          'data-analytics-id': 'reset-drawer-button',
        }}
      >
        <Button data-test-id="resetButton" type="link" disabled={loading}>
          {t('reset')}
        </Button>
      </Popconfirm>
      <Flex gap={spacingXS}>
        <Popconfirm
          overlayClassName="sig-popconfirm"
          icon={null}
          disabled={!hasChanges}
          title={popOverTitle(
            t('confirmChanges.title'),
            t('confirmChanges.subtext')
          )}
          onConfirm={onClear}
          okText={t('confirmChanges.cancel')}
          cancelText={t('confirmChanges.keep')}
          cancelButtonProps={{
            ...cancelButtonProps,
            'data-analytics-id': 'keep-drawer-button',
          }}
          okButtonProps={{
            ...okButtonProps,
            'data-analytics-id': 'reset-drawer-button',
          }}
        >
          <Button
            disabled={loading}
            data-test-id="cancelChangesButton"
            onClick={onCancel}
            type="text"
          >
            {t('cancel')}
          </Button>
        </Popconfirm>
        <Button
          onClick={onApply}
          disabled={!hasChanges || loading}
          data-test-id="okApplyButton"
          type="primary"
        >
          {t('apply')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default ThirdGenColumnDrawerFooter
