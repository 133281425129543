import { Modal, Alert, Radio } from 'antd'
import { FC, ReactNode, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Space, Text, useIsThirdGen } from '@signifyd/components'
import { DUPLICATE_PREDICTION_LIST_ENTRY_ACTION } from '@signifyd/http'
import { ButtonProps } from 'antd/lib/button'
import styles from './ResolveDuplicatesModal.less'

interface Props {
  onSelect: (action: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION) => void
  onBack: () => void
  isVisible: boolean
  isLoading: boolean
  modalDescription: ReactNode
}

const bulkActionsTranslations = 'bulkActions.bulkAddToList.duplicatesModal'

export const DEFAULT_DUPLICATE_ACTION =
  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.DISCARD

const ResolveDuplicatesModal: FC<Props> = ({
  onSelect,
  onBack,
  isVisible,
  isLoading,
  modalDescription,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  const [action, setAction] = useState(DEFAULT_DUPLICATE_ACTION)

  return (
    <Modal
      title={t(`${bulkActionsTranslations}.title`)}
      open={isVisible}
      closable={false}
      okType={isThirdGen ? 'primary' : 'link'}
      cancelText={t(`${bulkActionsTranslations}.backButton`)}
      cancelButtonProps={
        {
          'data-analytics-id': 'bulk-actions-duplicates-cancel',
          type: 'link',
        } as unknown as ButtonProps
      }
      okText={t(`${bulkActionsTranslations}.submitButton`)}
      okButtonProps={
        {
          'data-analytics-id': 'bulk-actions-duplicates-ok',
          type: 'link',
        } as unknown as ButtonProps
      }
      onOk={() => onSelect(action)}
      onCancel={() => {
        setAction(DEFAULT_DUPLICATE_ACTION)
        onBack()
      }}
      confirmLoading={isLoading}
    >
      <Alert
        message={
          <Text size="sm">{t(`${bulkActionsTranslations}.warning`)}</Text>
        }
        type="warning"
        showIcon
      />
      <Space size="sm" />
      <Text size="sm" data-test-id="resolveDuplicateFormHint">
        {modalDescription}
      </Text>
      <Space size="sm" />
      <Radio.Group
        onChange={(e) => setAction(e.target.value)}
        value={action}
        disabled={isLoading}
      >
        <Radio value={DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.DISCARD}>
          <div data-test-id="discardDuplicates" className={styles.radio}>
            {t(`${bulkActionsTranslations}.discardLabel`)}
            <Text size="xs" block>
              {t(`${bulkActionsTranslations}.discardDescription`)}
            </Text>
          </div>
        </Radio>
        <Space size="sm" />
        <Radio value={DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.OVERWRITE}>
          <div data-test-id="overwriteDuplicates" className={styles.radio}>
            {t(`${bulkActionsTranslations}.overwriteLabel`)}
            <Text size="xs" block>
              <Trans
                i18nKey={`${bulkActionsTranslations}.overwriteDescription`}
                components={{ bold: <Text weight="semibold" /> }}
              />
            </Text>
          </div>
        </Radio>
      </Radio.Group>
    </Modal>
  )
}

export default ResolveDuplicatesModal
