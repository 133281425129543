import { ReactNode } from 'react'
import { ExternalLink } from '@signifyd/components'
import { InvestigationInfo } from '@signifyd/http'

const CustomerCell = (_text: null, rowData: InvestigationInfo): ReactNode =>
  rowData.customerName && (
    <ExternalLink url={`/casesearch?customerId=${rowData.customerId}`}>
      {rowData.customerName}
    </ExternalLink>
  )

export default CustomerCell
