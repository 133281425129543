import { Radio } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FILTER_JOIN_TYPE } from '@signifyd/utils'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import { useGlobalFilterJoinType } from 'pages/InvestigationSearchPage/queries/useGlobalFilterJoinType'
import useNotifyOnRefresh from './useNotifyOnRefresh'

const SearchTypeSwitch: FC = () => {
  const { t } = useTranslation()

  const { joinType, setJoinType } = useGlobalFilterJoinType()
  const { selectedRowKeys } = useTableContext()

  useNotifyOnRefresh(joinType)

  return (
    <Radio.Group
      value={joinType}
      onChange={(e) => setJoinType(e.target.value as FILTER_JOIN_TYPE)}
      disabled={!!selectedRowKeys.length}
    >
      <Radio value={FILTER_JOIN_TYPE.AND} data-analytics-id="and-filter-radio">
        {t('investigationSearch.searchTypeSwitch.and')}
      </Radio>
      <Radio value={FILTER_JOIN_TYPE.OR} data-analytics-id="or-filter-radio">
        {t('investigationSearch.searchTypeSwitch.or')}
      </Radio>
    </Radio.Group>
  )
}

export default SearchTypeSwitch
