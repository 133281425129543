import { Modal, Select } from 'antd'
import { FC, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Text, useIsThirdGen } from '@signifyd/components'
import { PREDICTION_LIST_TYPE, PredictionList } from '@signifyd/http'
import { ButtonProps } from 'antd/lib/button'
import { useBulkActionsContext } from '../../BulkActionsProvider'
import { MODAL_WIDTH } from '../../constants'
import { useGetBulkActionPredictionLists } from '../../queries/useGetBulkActionsPredictionLists'
import styles from './SelectListModal.less'

interface Props {
  predictionListType: PREDICTION_LIST_TYPE
  onPrevious: () => void
  onNext: (predictionList: PredictionList) => void
}

const SelectListModal: FC<Props> = ({
  predictionListType,
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  const [selectedList, setSelectedList] = useState<PredictionList>()

  const { bulkActionStage, resetAddToList } = useBulkActionsContext()

  const handleOk = (): void => {
    if (selectedList) {
      onNext(selectedList)
    }
  }

  const { data: predictionListData } = useGetBulkActionPredictionLists()

  const lists = predictionListData?.lists?.[predictionListType]

  if (!lists) {
    return null
  }

  return (
    <Modal
      okType={isThirdGen ? 'primary' : 'link'}
      cancelButtonProps={
        {
          'data-analytics-id': 'bulk-actions-select-list-cancel',
          type: 'link',
          onClick: onPrevious,
        } as ButtonProps
      }
      maskClosable={false}
      closable
      destroyOnClose={false}
      width={MODAL_WIDTH}
      open={bulkActionStage === 'SELECT_LIST'}
      onCancel={() => {
        setSelectedList(undefined)
        resetAddToList()
      }}
      onOk={handleOk}
      okText={t('bulkActions.bulkAddToList.selectListModal.continueButton')}
      cancelText={t('bulkActions.bulkAddToList.selectListModal.backButton')}
      okButtonProps={
        {
          'data-analytics-id': 'bulk-actions-select-list-ok',
          'data-test-id': 'selectListContinueButton',
          disabled: !selectedList,
        } as ButtonProps
      }
      title={t('bulkActions.bulkAddToList.selectListModal.title')}
    >
      <Text>{t('bulkActions.bulkAddToList.selectListModal.description')}</Text>
      <Select
        onChange={(value: string) => {
          const listObj = lists.find((list) => list.id === Number(value))

          if (listObj) {
            setSelectedList(listObj)
          }
        }}
        className={styles.select}
        value={selectedList?.name}
        placeholder={t(
          'bulkActions.bulkAddToList.selectListModal.selectListPlaceholder'
        )}
        data-test-id="selectList"
        options={lists.map(({ id, name, size }) => ({
          key: id,
          label: (
            <>
              <Text className={styles.listName}>{name}</Text>
              <Text className={styles.entries}>
                <span>{size}</span>
                <Trans
                  i18nKey="bulkActions.bulkAddToList.selectListModal.items"
                  values={{ count: size }}
                />
              </Text>
            </>
          ),
          value: id,
          className: styles.option,
        }))}
      />
    </Modal>
  )
}

export default SelectListModal
