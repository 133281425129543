import { UserConfigs } from '@signifyd/app-feature-configs'
import { useUserContext } from '@signifyd/components'
import { User, UserTeam, USER_ROLE, USER_FEATURE } from '@signifyd/http'

export interface UserData {
  currentUser: User
  teams: Array<UserTeam>
  isTraining: boolean
  isAdmin: boolean
  hasAccess: boolean
  userConfigs: UserConfigs
}

export const useGetUserData = (): UserData => {
  const { user, teams, userConfigs } = useUserContext()

  return {
    currentUser: user,
    teams,
    hasAccess: !!user.features[USER_FEATURE.POWER_SEARCH],
    isAdmin: user.isAdmin,
    isTraining: user.roles.includes(USER_ROLE.TRAINING),
    userConfigs,
  }
}

export default useGetUserData
